import { Box, ButtonSecondary, DataCard, Grid, IconDownloadRegular, IconSearchFileRegular, LoadingBar, SkeletonRectangle, skinVars, useWindowSize } from '@telefonica/mistica';
import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import slugify from 'react-slugify';
import { Product } from '../../entities/products/product';
import { ProductDocument } from '../../entities/products/product-document';
import { getDocument, getGeneralDocumentInformation } from '../../utils/apiclient';
import { getCurrentUserProduct, getUser } from '../../utils/storage';
import { isControlCommision, isPromoter, trackEvent } from '../../utils/utils';
import EmptyCard from '../empty-card/EmptyCard';

interface DocumentationProps {
    full?:boolean,
    product: Product | null,
    sustainability?: boolean,
    legal?: boolean,
    printed?: boolean,
    all?: boolean,
    loading?: boolean,
    prefixSlug?:string
}

const Documentation: React.FC<DocumentationProps> = (prop) => {
    const intl = useIntl()
    const size = useWindowSize();
    const [columnGrid, setColumnGrid] = useState(3 as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12);
    const [documents, setDocuments]= React.useState([] as Array<ProductDocument>);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [loadingDocument, setLoadingDocument] = useState(null as string|null);
    const currentUserProduct = getCurrentUserProduct();

    useEffect(()=>{
        if(prop.product && prop.product.documentacion){
            const noFilter = isPromoter() || isControlCommision();

            let documents: ProductDocument[]  = [];
            if(noFilter){
                documents = prop.product.documentacion;
            }else{
                if(prop.sustainability!=undefined){
                    documents = documents.concat(prop.product.documentacion.filter(item=>(item.esSO??false)===(prop.sustainability ?? false)));
                }
                if(prop.legal!=undefined){
                    documents = documents.concat(prop.product.documentacion.filter(item=>(item.esDL??false)===(prop.legal ?? false)));
                }
                if(prop.printed!=undefined){
                    documents = documents.concat(prop.product.documentacion.filter(item=>(item.esEI??false)===(prop.printed ?? false)));
                }
                if(prop.all!=undefined){
                    documents = prop.product.documentacion;
                }


                const includeDigs = (!prop.full && currentUserProduct && currentUserProduct.numContrato && (prop.legal ?? false));
                documents = documents.filter(item=>(item.esDIG??false)===false);
                if(includeDigs){
                    documents = documents.concat(prop.product.documentacion.filter(item=>(item.esDIG)));
                }
            }
            setDocuments(documents);
        }
    },[prop.product]);

    const downloadDocument= (document:ProductDocument) => {
        if(prop.prefixSlug){
            trackEvent(prop.prefixSlug + slugify(document.texto??'').replaceAll('-', '_'));
        }
        if(document.ruta && document.cdDocumento){
            setLoadingDownload(true);
            setLoadingDocument(document.cdDocumento);
            getDocument(document.ruta, document.cdDocumento).finally(()=>{
                setLoadingDownload(false);
                setLoadingDocument(null);
            })
        }
    };

    useEffect(()=>{
        if(prop.full){
            if(size.width<520){
                setColumnGrid(1);
            }else if(size.width<768){
                setColumnGrid(2);
            }else if(size.width<1200){
                setColumnGrid(3);
            }else{
                setColumnGrid(4);
            }
        }else{
            if(size.width<520){
                setColumnGrid(1);
            }else if(size.width<768){
                setColumnGrid(2);
            }else{
                setColumnGrid(3);
            }
        }

    }, [size]);

    return (<>{prop.loading ?
                <Box paddingY={12}>
                    <Grid columns={columnGrid} gap={24}>
                        <SkeletonRectangle height={169} />
                        <SkeletonRectangle height={169} />
                        <SkeletonRectangle height={169} />
                        <SkeletonRectangle height={169} />
                        <SkeletonRectangle height={169} />
                        <SkeletonRectangle height={169} />
                    </Grid>
                </Box>
                :
                prop.product ?
                <>
                    <LoadingBar visible={loadingDownload} />
                    <Box  paddingBottom={24} >
                        {documents.length===0 ?
                            <EmptyCard
                                icon = {<IconSearchFileRegular size={52} color={skinVars.colors.brand} />}
                                title='No hay documentos disponibles'
                            />
                        :
                            <Grid columns={columnGrid  } gap={24}>
                                {documents.map((document, index) => (
                                    <DataCard key={'document-'+document.cdDocumento}
                                        title={document.texto??''}
                                        button={
                                            <ButtonSecondary showSpinner={document.cdDocumento===loadingDocument} small onPress={() => {downloadDocument(document)}}>
                                                {intl.formatMessage({id:'page_investment_funds_customer_general_documentation_download'})}
                                                <IconDownloadRegular color='currentColor' />
                                        </ButtonSecondary>
                                        }
                                    />
                                ))}
                            </Grid>
                        }
                    </Box>
                </>
            : <></>
        }</>);
};

export default Documentation;
