import { IconAddMoreCircleFilled, IconAddMoreCircleRegular, IconBalanceRegular, IconComputerRegular, IconComputerUserRegular, IconDocumentsFilled, IconDocumentsRegular, IconDollarSymbolCircleRegular, IconEcoRegular, IconExchangeFilled, IconExchangeLight, IconFingerprintRegular, IconFlowerRegular, IconHomeFilled, IconHomeRegular, IconJusticeFilled, IconJusticeRegular, IconLockClosedRegular, IconMenuRegular, IconNumericKeypadRegular, IconPayInvoiceFilled, IconPayInvoiceRegular, IconPeopleNetworkRegular, IconSearchFileFilled, IconSearchFileRegular, IconTachometerRegular, IconUmbrellaRegular, IconUserAccountRegular } from '@telefonica/mistica';
import React, { ReactElement } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Language } from '../entities/commons/language.model';
import { NavOption } from '../entities/commons/nav-option.model';
import OnBoardingInitAppPage from '../pages/app/onboarding-init-app/OnBoardingInitAppPage';
import AlreadyUserPage from '../pages/private/login/AlreadyUserPage';
import BiometricLoginPage from '../pages/private/login/BiometricLoginPage';
import LoginPage from '../pages/private/login/LoginPage';
import UserBlockedPage from '../pages/private/login/UserBlockedPage';
import RegisterPage from '../pages/private/register/RegisterPage';
import FormalizeContractSignaturit from '../pages/private/signContract/FormalizeContractSignaturit';
import GlobalPositionControlCommisionPage from '../pages/private/users/control-commision/global-position/GlobalPositionControlCommisionPage';
import LegalDocumentationControlCommisionPage from '../pages/private/users/control-commision/legal-documentation/LegalDocumentationControlCommisionPage';
import MonthlyReportControlCommisionPage from '../pages/private/users/control-commision/monthly-report/MonthlyReportControlCommisionPage';
import OtherQueriesControlCommisionPage from '../pages/private/users/control-commision/other-queries/OtherQueriesControlCommisionPage';
import PrintedMatterControlCommisionPage from '../pages/private/users/control-commision/printed-matter/PrintedMatterControlCommisionPage';
import ChangeOperationsKeyCustomerPage from '../pages/private/users/customer/change-operations-key/ChangeOperationsKeyCustomerPage';
import ChangePasswordCustomerPage from '../pages/private/users/customer/change-password/ChangePasswordCustomerPage';
import ContractCustomerPage from '../pages/private/users/customer/contract/ContractCustomerPage';
import ContractsCustomerPage from '../pages/private/users/customer/contracts/ContractsCustomerPage';
import GlobalPositionCustomerPage from '../pages/private/users/customer/global-position/GlobalPositionCustomerPage';
import ContractInvestmentFundsCustomerPage from '../pages/private/users/customer/investment-funds/ContractInvestmentFundsCustomerPage';
import InfoInvestmentFundsCustomerPage from '../pages/private/users/customer/investment-funds/InfoInvestmentFundsCustomerPage';
import InvestmentFundsCustomerPage from '../pages/private/users/customer/investment-funds/InvestmentFundsCustomerPage';
import OperateInvestmentFundsCustomerPage from '../pages/private/users/customer/investment-funds/OperateInvestmentFundsCustomerPage';
import { RefundInvestmentFundsCustomerPage, SubscriptionInvestmentFundsCustomerPage, TransferInvestmentFundsCustomerPage } from '../pages/private/users/customer/investment-funds/OperationsInvestmentFundsCustomerPage';
import MenuCustomerPage from '../pages/private/users/customer/menu/MenuCustomerPage';
import NoticesCustomerPage from '../pages/private/users/customer/notices/NoticesCustomerPage';
import ConfigureOnlineCorrespondenceCustomerPage from '../pages/private/users/customer/online-correspondence/ConfigureOnlineCorrespondenceCustomerPage';
import OnlineCorrespondenceCustomerPage from '../pages/private/users/customer/online-correspondence/OnlineCorrespondenceCustomerPage';
import OperationsInProgressCustomerPage from '../pages/private/users/customer/operations-in-progress/OperationsInProgressCustomerPage';
import OperationsInProgressDetailCustomerPage from '../pages/private/users/customer/operations-in-progress/OperationsInProgressDetailCustomerPage';
import OperateOtherProductsCustomerPage from '../pages/private/users/customer/other-products/OperateOtherProductsCustomerPage';
import OtherProductsCustomerPage from '../pages/private/users/customer/other-products/OtherProductsCustomerPage';
import { ContractEmploymentPlansCustomerPage, ContractIndividualPlansCustomerPage, ContractSimplifiedEmploymentPlansCustomerPage } from '../pages/private/users/customer/pension-plans/ContractPensionPlansCustomerPage';
import { InfoEmploymentPlansCustomerPage, InfoIndividualPlansCustomerPage, InfoSimplifiedEmploymentPlansCustomerPage } from '../pages/private/users/customer/pension-plans/InfoPensionPlanCustomerPage';
import { OperateEmploymentPlansCustomerPage, OperateIndividualPlansCustomerPage, OperateSimplifiedEmploymentPlansCustomerPage } from '../pages/private/users/customer/pension-plans/OperatePensionPlanCustomerPage';
import { ContributionsEmploymentPlansCustomerPage, ContributionsIndividualPlansCustomerPage, ContributionsSimplifiedEmploymentPlansCustomerPage, MobilizeEmploymentPlansCustomerPage, MobilizeIndividualPlansCustomerPage, MobilizeSimplifiedEmploymentPlansCustomerPage } from '../pages/private/users/customer/pension-plans/OperationsPensionPlansCustomerPage';
import { EmploymentPlansCustomerPage, IndividualPlansCustomerPage, SimplifiedEmploymentPlansCustomerPage } from '../pages/private/users/customer/pension-plans/PensionPlansCustomerPage';
import SignContractSimplifiedEmploymentPlansCustomerPage from '../pages/private/users/customer/pension-plans/SignContractSimplifiedEmploymentPlansCustomerPage';
import ProfileCustomerPage from '../pages/private/users/customer/profile/ProfileCustomerPage';
import TaxInformationCustomerPage from '../pages/private/users/customer/tax-information/TaxInformationCustomerPage';
import TaxInformationInvestmentFundsCustomerPage from '../pages/private/users/customer/tax-information/TaxInformationInvestmentFundsCustomerPage';
import TaxInformationPensionPlansCustomerPage from '../pages/private/users/customer/tax-information/TaxInformationPensionPlansCustomerPage';
import UserCopyrightPage from '../pages/private/users/fonditel/UserCopyrightPage';
import UserOperationalWebPage from '../pages/private/users/fonditel/UserOperationalWebPage';
import ContributionsPromoterPage from '../pages/private/users/promoter/contributions/ContributionsPromoterPage';
import GlobalPositionPromoterPage from '../pages/private/users/promoter/global-position/GlobalPositionPromoterPage';
import LegalDocumentationPromoterPage from '../pages/private/users/promoter/legal-documentation/LegalDocumentationPromoterPage';
import MonthlyReportPromoterPage from '../pages/private/users/promoter/monthly-report/MonthlyReportPromoterPage';
import OtherQueriesPromoterPage from '../pages/private/users/promoter/other-queries/OtherQueriesPromoterPage';
import PrintedMatterPromoterPage from '../pages/private/users/promoter/printed-matter/PrintedMatterPromoterPage';
import RegisterParticipantsPromoterPage from '../pages/private/users/promoter/register-participants/RegisterParticipantsPromoterPage';
import WorkerProfilePromoterPage from '../pages/private/users/promoter/worker-profile/WorkerProfilePromoterPage';
import SelectProfile from '../pages/private/users/select-profile/SelectProfile';
import UserMenuPage from '../pages/private/users/user-menu/UserMenuPage';
import AboutUsPage from '../pages/public/about-us/AboutUsPage';
import CertificatesPage from '../pages/public/certificates/CertificatesPage';
import ContactPage from '../pages/public/contact/ContactPage';
import CookiesPolicyPage from '../pages/public/cookies-policy/CookiesPolicyPage';
import CopyrightPage from '../pages/public/copyright/CopyrightPage';
import DataProtectionPage from '../pages/public/data-protection/DataProtectionPage';
import EmploymentSystemPensionPlansPage from '../pages/public/employment-system-pension-plans/EmploymentSystemPensionPlansPage';
import Error404Page from '../pages/public/error-404/Error404Page';
import ForInvestorPage from '../pages/public/for-investor/ForInvestorPage';
import HomePage from '../pages/public/home/HomePage';
import InvestmentFundsPage from '../pages/public/investment-funds/InvestmentFundsPage';
import LegalNoticePage from '../pages/public/legal-notice/LegalNoticePage';
import MaintenancePage from '../pages/public/maintenance/MaintenancePage';
import NewsDetailPage from '../pages/public/news/NewsDetailPage';
import NewsPage from '../pages/public/news/NewsPage';
import OperationalWebPage from '../pages/public/operational-web/OperationalWebPage';
import PensionPlansPage from '../pages/public/pension-plans/PensionPlansPage';
import { getCurrentLang } from './locate';
import { getFirstTimeApp, getModeCallCenter, getModeMaintenance, getUser } from './storage';
import { havePermission, isNativeApp } from './utils';
import PensionPlansDetailPage from '../pages/public/pension-plans/PensionPlansDetailPage';
import InvestmentFundsDetailPage from '../pages/public/investment-funds/InvestmentFundsDetailPage';
import CommissionSavingsCalculatorPage from '../pages/public/for-investor/calculators/CommissionSavingsCalculatorPage';
import FinalCapitalCalculatorPage from '../pages/public/for-investor/calculators/FinalCapitalCalculatorPage';
import CustomizeInvestmentFundPage from '../pages/public/for-investor/calculators/DecideYourInvestmentCalculatorPage';
import ComplementarySavingsCalculatorPage from '../pages/public/for-investor/calculators/ComplementarySavingsCalculatorPage';
import SolutionForCompaniesPage from '../pages/public/employment-system-pension-plans/SolutionsForCompaniesPage';
import SolutionsForFreelancersPage from '../pages/public/employment-system-pension-plans/SolutionsForFreelancersPage';
import FiscalExerciseIndividualPensionPlansPage from '../pages/public/landings/fiscal-exercise/FiscalExerciseIndividualPensionPlansPage';
import FiscalExerciseInvestmentFundsPage from '../pages/public/landings/fiscal-exercise/FiscalExerciseInvestmentFundsPage';
import FiscalExerciseEmploymentPensionPlans from '../pages/public/landings/fiscal-exercise/FiscalExerciseEmploymentPensionPlansPage';
import EuroHorizonInformationPage from '../pages/public/landings/releases/euro-horizon/EuroHorizonInformationPage';
import EuroHorizonSubscriptionPage from '../pages/public/landings/releases/euro-horizon/EuroHorizonSubscriptionPage';
import EuroHorizonWalletPage from '../pages/public/landings/releases/euro-horizon/EuroHorizonWalletPage';
import OfficePage from '../pages/public/landings/office/OfficePage';
import IsrInFonditelPage from '../pages/public/landings/sustainability/IsrInFonditelPage';
import IncompleteRegistrationPage from '../pages/private/incomplete-registration/IncompleteRegistrationPage';
import UserDataProtectionPage from '../pages/private/users/fonditel/UserDataProtectionPage';
import UserContactPage from '../pages/private/users/fonditel/UserContactPage';
import CallCenterPage from '../pages/private/callcenter/CallCenterPage';
import RecordEmploymentRegulationPage from '../pages/public/landings/record-employment-regulation/RecordEmploymentRegulationPage';
import FiscalExercisePage from '../pages/public/landings/fiscal-exercise-2024/FiscalExercisePage';
import DownloadDocumentPage from '../pages/public/download-document/DownloadDocument';
import ConfirmationMailBAPage from '../pages/public/signContract/ConfirmationMailBA';
import TechnicalClaimsPage from '../pages/public/technical-claims/TechnicalClaims';

export const Pages = (): JSX.Element => {
    return (
      getModeMaintenance() ?
      <Switch>
        <Route exact key='es' path='/'>
          <Redirect to='/mantenimiento'></Redirect>
        </Route>
      <Route exact key='es' path='/mantenimiento'>
        <MaintenancePage />
      </Route>
      <Route exact key='en' path='/en/maintenance'>
        <MaintenancePage />
      </Route>
      <Route path='*'>
        <Redirect to='/mantenimiento'></Redirect>
      </Route>

    </Switch>
    :
    <Switch>
      <Route exact key='es' path='/'>
        {
          getModeMaintenance() ?
            <Redirect to='/mantenimiento'></Redirect>
          :
            getModeCallCenter()!=null ?
              <Redirect to='/callcenter'></Redirect>
            :
              (isNativeApp ? (getFirstTimeApp() ? <Redirect to='/onboarding-init-app'></Redirect> : <Redirect to='/acceso'></Redirect>) : <HomePage />)
        }
      </Route>
      <Route exact key='es' path='/nosotros'>
        <AboutUsPage key='NOSOTROS'/>
      </Route>
      <Route exact key='es' path='/planes-empleo-autonomos'>
        <EmploymentSystemPensionPlansPage key='PLANES_PENSIONES_AUTONOMOS_PUBLICO'/>
      </Route>
      <Route exact key='es' path='/planes-empleo-autonomos/soluciones-empresas'>
        <SolutionForCompaniesPage key='PLANES_PENSIONES_AUTONOMOS_SOLUCIONES_EMPRESAS_PUBLICO'/>
      </Route>
      <Route exact key='es' path='/planes-empleo-autonomos/soluciones-autonomos'>
        <SolutionsForFreelancersPage key='PLANES_PENSIONES_AUTONOMOS_SOLUCIONES_AUTONOMOS_PUBLICO'/>
      </Route>
      <Route exact key='es' path='/fondos-inversion'>
        <InvestmentFundsPage key='FONDOS_INVERSION_PUBLICO'/>
      </Route>
      <Route exact key='es' path='/fondos-inversion/:slug'>
        <InvestmentFundsDetailPage key='FONDOS_INVERSION_PUBLICO'/>
      </Route>
      <Route exact key='es' path='/planes-pensiones'>
        <PensionPlansPage key='PLANES_PENSIONES_PUBLICO'/>
      </Route>
      <Route exact key='es' path='/planes-pensiones/:slug'>
        <PensionPlansDetailPage key='PLANES_PENSIONES_PUBLICO'/>
      </Route>
      <Route exact key='es' path='/inversor'>
        <ForInvestorPage key='INVERSOR'/>
      </Route>
      <Route exact key='es' path='/inversor/calculadora-ahorro-comisiones'>
        <CommissionSavingsCalculatorPage key='CALCULADORA_AHORRO_COMISIONES'/>
      </Route>
      <Route exact key='es' path='/inversor/calculadora-capital-final'>
        <FinalCapitalCalculatorPage key='CALCULADORA_CAPITAL_FINAL_AHORRO_FISCAL'/>
      </Route>
      <Route exact key='es' path='/inversor/personaliza-inversion'>
        <CustomizeInvestmentFundPage key='SIMULADOR_DECIDE_TU_INVERSION'/>
      </Route>
      <Route exact key='es' path='/inversor/calculadora-ahorro-complementario'>
        <ComplementarySavingsCalculatorPage key='INVERSOR_CALCULADORA_AHORRO_COMPLEMENTARIO'/>
      </Route>
      <Route exact key='es' path='/noticias'>
        <NewsPage key='NOTICIAS'/>
      </Route>
      <Route exact key='es' path='/noticias/:id/:preview?'>
        <NewsDetailPage key='NOTICIAS_DETALLE'/>
      </Route>
      <Route exact key='es' path='/contacto'>
        <ContactPage key='CONTACTO_PUBLICO'/>
      </Route>
      <Route exact key='es' path='/reclamaciones-tecnicas'>
        <TechnicalClaimsPage key='RECLAMACIONES_TECNICAS'/>
      </Route>
      <Route exact key='es' path='/certificados'>
        <CertificatesPage key='CERTIFICADOS'/>
      </Route>
      <Route exact key='es' path='/aviso-legal'>
        <LegalNoticePage key='AVISO_LEGAL'/>
      </Route>
      <Route exact key='es' path='/politica-cookies'>
        <CookiesPolicyPage key='POLITICA_COOKIES'/>
      </Route>
      <Route exact key='es' path='/copyright'>
        <CopyrightPage key='COPYRIGHT_PUBLICO'/>
      </Route>
      <Route exact key='es' path='/operativa-web'>
        <OperationalWebPage key='OPERATIVA_WEB_PUBLICO'/>
      </Route>
      <Route exact key='es' path='/proteccion-datos'>
        <DataProtectionPage key='PROTECCION_DE_DATOS_PUBLICO'/>
      </Route>
      <Route exact key='es' path='/onboarding-init-app'>
        <OnBoardingInitAppPage key='ONBOARDING_INIT_APP'/>
      </Route>
      <Route exact key='es' path='/callcenter'>
        <CallCenterPage key='CALLCENTER'/>
      </Route>
      <Route exact key='es' path='/acceso'>
        <LoginPage key='LOGIN'/>
      </Route>
      <Route exact key='es' path='/recuperacion-password'>
        <LoginPage key='LOGIN_RP'/>
      </Route>
      <Route exact key='es' path='/recuperacion-clave-operaciones'>
        <LoginPage key='LOGIN_RCO'/>
      </Route>
      <Route exact key='es' path='/login-biometrico'>
        <BiometricLoginPage key='LOGIN_BIOMETRICO'/>
      </Route>
      <Route exact key='es' path='/usuario-bloqueado'>
        <UserBlockedPage key='USUARIO_BLOQUEADO'/>
      </Route>
      <Route exact key='es' path='/aun-no-eres-usuario'>
        <AlreadyUserPage />
      </Route>
      <Route exact key='es' path='/registro'>
        <RegisterPage key='REGISTRO'/>
      </Route>
      <Route exact key='es' path='/activar-cuenta'>
        <RegisterPage key='REGISTRO_ACTIVAR_CUENTA'/>
      </Route>
      <Route exact key='es' path='/registro-incompleto'>
        <IncompleteRegistrationPage key='REGISTRO_INCOMPLETO'/>
      </Route>
      
      <Route exact key='es' path='/area-privada/seleccione-perfil'>
        <SelectProfile key='SELECT_PROFILE' />
      </Route>
      <Route exact key='es' path='/area-privada/operativa-web'>
        <UserOperationalWebPage key='OPERATIVA_WEB'/>
      </Route>
      <Route exact key='es' path='/area-privada/proteccion-datos'>
        <UserDataProtectionPage key='PROTECCION_DE_DATOS'/>
      </Route>
      <Route exact key='es' path='/area-privada/copyright'>
        <UserCopyrightPage key='COPYRIGHT'/>
      </Route>
      <Route exact key='es' path='/area-privada/contacto'>
        <UserContactPage key='CONTACTO'/>
      </Route>
      <Route exact key='es' path='/area-privada/menu-usuario'>
        <UserMenuPage key='MENU_USUARIO'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/posicion-global'>
        <GlobalPositionCustomerPage key='POSICION_GLOBAL'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/planes-pensiones/planes-empleo'>
        <EmploymentPlansCustomerPage key='PLANES_PENSIONES_EMPLEO'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/planes-pensiones/planes-empleo/informacion-producto'>
        <InfoEmploymentPlansCustomerPage key='PLANES_PENSIONES_EMPLEO_INFORMACION_PRODUCTO'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/planes-pensiones/planes-empleo/contratar'>
        <ContractEmploymentPlansCustomerPage key='PLANES_PENSIONES_EMPLEO_CONTRATAR'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/planes-pensiones/planes-empleo/operar'>
        <OperateEmploymentPlansCustomerPage key='PLANES_PENSIONES_EMPLEO_OPERAR'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/planes-pensiones/planes-empleo/operar/aportacion'>
        <ContributionsEmploymentPlansCustomerPage key='PLANES_PENSIONES_EMPLEO_OPERAR_APORTACION'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/planes-pensiones/planes-empleo/operar/movilizar'>
        <MobilizeEmploymentPlansCustomerPage key='PLANES_PENSIONES_EMPLEO_OPERAR_MOVILIZAR'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/planes-pensiones/planes-individuales'>
        <IndividualPlansCustomerPage key='PLANES_PENSIONES_INDIVIDUALES'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/planes-pensiones/planes-individuales/informacion-producto'>
        <InfoIndividualPlansCustomerPage key='PLANES_PENSIONES_INDIVIDUALES_INFORMACION_PRODUCTO'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/planes-pensiones/planes-individuales/contratar'>
        <ContractIndividualPlansCustomerPage key='PLANES_PENSIONES_INDIVIDUALES_CONTRATAR'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/planes-pensiones/planes-individuales/operar'>
        <OperateIndividualPlansCustomerPage key='PLANES_PENSIONES_INDIVIDUALES_OPERAR'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/planes-pensiones/planes-individuales/operar/aportacion'>
        <ContributionsIndividualPlansCustomerPage key='PLANES_PENSIONES_INDIVIDUALES_OPERAR_APORTACION'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/planes-pensiones/planes-individuales/operar/movilizar'>
        <MobilizeIndividualPlansCustomerPage key='PLANES_PENSIONES_INDIVIDUALES_OPERAR_MOVILIZAR'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/planes-pensiones/planes-empleo-simplificados'>
        <SimplifiedEmploymentPlansCustomerPage key='PLANES_PENSIONES_EMPLEO_SIMPLIFICADOS'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/planes-pensiones/planes-empleo-simplificados/informacion-producto'>
        <InfoSimplifiedEmploymentPlansCustomerPage key='PLANES_PENSIONES_EMPLEO_SIMPLIFICADOS_INFORMACION_PRODUCTO'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/planes-pensiones/planes-empleo-simplificados/contratar'>
        <ContractSimplifiedEmploymentPlansCustomerPage key='PLANES_PENSIONES_EMPLEO_SIMPLIFICADOS_CONTRATAR'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/planes-pensiones/planes-empleo-simplificados/firmar'>
        <SignContractSimplifiedEmploymentPlansCustomerPage key='PLANES_PENSIONES_EMPLEO_SIMPLIFICADOS_FIRMAR'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/planes-pensiones/planes-empleo-simplificados/operar'>
        <OperateSimplifiedEmploymentPlansCustomerPage key='PLANES_PENSIONES_EMPLEO_SIMPLIFICADOS_OPERAR'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/planes-pensiones/planes-empleo-simplificados/operar/aportacion'>
        <ContributionsSimplifiedEmploymentPlansCustomerPage key='PLANES_PENSIONES_EMPLEO_SIMPLIFICADOS_APORTACION'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/planes-pensiones/planes-empleo-simplificados/operar/movilizar'>
        <MobilizeSimplifiedEmploymentPlansCustomerPage key='PLANES_PENSIONES_EMPLEO_SIMPLIFICADOS_MOVILIZAR'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/fondos-inversion'>
        <InvestmentFundsCustomerPage key='FONDOS_INVERSION'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/fondos-inversion/informacion-producto'>
        <InfoInvestmentFundsCustomerPage key='FONDOS_INVERSION_INFORMACION_PRODUCTO'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/fondos-inversion/contratar'>
        <ContractInvestmentFundsCustomerPage key='FONDOS_INVERSION_CONTRATAR'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/fondos-inversion/operar'>
        <OperateInvestmentFundsCustomerPage key='FONDOS_INVERSION_OPERAR'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/fondos-inversion/operar/suscripcion'>
        <SubscriptionInvestmentFundsCustomerPage key='FONDOS_INVERSION_OPERAR_SUSCRIPCION'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/fondos-inversion/operar/reembolso'>
        <RefundInvestmentFundsCustomerPage key='FONDOS_INVERSION_OPERAR_REEMBOLSO'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/fondos-inversion/operar/traspaso'>
        <TransferInvestmentFundsCustomerPage key='FONDOS_INVERSION_OPERAR_TRASPASO'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/otros-productos'>
        <OtherProductsCustomerPage key='OTROS_PRODUCTOS'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/otros-productos/operar'>
        <OperateOtherProductsCustomerPage key='OTROS_PRODUCTOS_OPERAR'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/informacion-fiscal'>
        <TaxInformationCustomerPage key='INFORMACION_FISCAL'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/informacion-fiscal/planes-pensiones'>
        <TaxInformationPensionPlansCustomerPage key='INFORMACION_FISCAL_PLANES_PENSIONES'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/informacion-fiscal/fondos-inversion'>
        <TaxInformationInvestmentFundsCustomerPage key='INFORMACION_FISCAL_FONDOS_INVERSION'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/correspondencia-online'>
        <OnlineCorrespondenceCustomerPage key='CORRESPONDENCIA_ONLINE'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/correspondencia-online/configurar'>
        <ConfigureOnlineCorrespondenceCustomerPage key='CORRESPONDENCIA_ONLINE_CONFIGURAR'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/operaciones-curso'>
        <OperationsInProgressCustomerPage key='OPERACIONES_CURSO'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/operaciones-curso/detalle'>
        <OperationsInProgressDetailCustomerPage key='OPERACIONES_CURSO_DETALLE'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/avisos'>
        <NoticesCustomerPage key='AVISOS'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/contratos'>
        <ContractsCustomerPage key='CONTRATOS'/>
      </Route>
      <Route exact key='es' path='/area-privada/formalizar-contrato'>
        <FormalizeContractSignaturit key='FORMALIZAR_CONTRATO'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/perfil'>
        <ProfileCustomerPage key='PERFIL'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/cambiar-password'>
        <ChangePasswordCustomerPage key='CAMBIAR_PASSWORD'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/cambiar-clave-operaciones'>
        <ChangeOperationsKeyCustomerPage key='CAMBIAR_CLAVE_OPERACIONES'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/contratar'>
        <ContractCustomerPage key='CONTRATAR'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes/menu'>
        <MenuCustomerPage key='CLIENTES_MENU'/>
      </Route>
      <Route exact key='es' path='/area-privada/clientes'>
        <GlobalPositionCustomerPage key='POSICION_GLOBAL_CLIENTES'/>
      </Route>
      <Route exact key='es' path='/area-privada/comision-control/posicion-global'>
        <GlobalPositionControlCommisionPage key='POSICION_GLOBAL_COMISION_CONTROL'/>
      </Route>
      <Route exact key='es' path='/area-privada/comision-control/documentacion-legal'>
        <LegalDocumentationControlCommisionPage key='COMISION_CONTROL_DOCUMENTACION_LEGAL'/>
      </Route>
      <Route exact key='es' path='/area-privada/comision-control/informe-mensual'>
        <MonthlyReportControlCommisionPage key='COMISION_CONTROL_INFORME_MENSUAL'/>
      </Route>
      <Route exact key='es' path='/area-privada/comision-control/impresos'>
        <PrintedMatterControlCommisionPage key='COMISION_CONTROL_IMPRESOS'/>
      </Route>
      <Route exact key='es' path='/area-privada/comision-control/otras-consultas'>
        <OtherQueriesControlCommisionPage key='COMISION_CONTROL_OTRAS_CONSULTAS'/>
      </Route>
      <Route exact key='es' path='/area-privada/comision-control'>
        <GlobalPositionControlCommisionPage key='COMISION_CONTROL'/>
      </Route>
      <Route exact key='es' path='/area-privada/promotor/posicion-global'>
        <GlobalPositionPromoterPage key='POSICION_GLOBAL_PROMOTOR'/>
      </Route>
      <Route exact key='es' path='/area-privada/promotor/alta-participes'>
        <RegisterParticipantsPromoterPage key='PROMOTOR_ALTA_PARTICIPES'/>
      </Route>
      <Route exact key='es' path='/area-privada/promotor/aportaciones'>
        <ContributionsPromoterPage key='PROMOTOR_APORTACIONES'/>
      </Route>
      <Route exact key='es' path='/area-privada/promotor/tipo-trabajador'>
        <WorkerProfilePromoterPage key='PROMOTOR_TIPO_TRABAJADOR'/>
      </Route>
      <Route exact key='es' path='/area-privada/promotor/documentacion-legal'>
        <LegalDocumentationPromoterPage key='PROMOTOR_DOCUMENTACION_LEGAL'/>
      </Route>
      <Route exact key='es' path='/area-privada/promotor/informe-mensual'>
        <MonthlyReportPromoterPage key='PROMOTOR_INFORME_MENSUAL'/>
      </Route>
      <Route exact key='es' path='/area-privada/promotor/impresos'>
        <PrintedMatterPromoterPage key='PROMOTOR_IMPRESOS'/>
      </Route>
      <Route exact key='es' path='/area-privada/promotor/otras-consultas'>
        <OtherQueriesPromoterPage key='PROMOTOR_OTRAS_CONSULTAS'/>
      </Route>
      <Route exact key='es' path='/area-privada/promotor'>
        <GlobalPositionPromoterPage key='PROMOTOR'/>
      </Route>
      <Route exact key='es' path='/mantenimiento'>
        <MaintenancePage key='MANTENIMIENTO'/>
      </Route>
      <Route exact key='es' path='/404'>
        <Error404Page key='404'/>
      </Route>
      <Route exact key='es' path='/download-document'>
        <DownloadDocumentPage key='download-document'/>
      </Route>
      <Route exact key='es' path='/correo-confirmacion'>
        <ConfirmationMailBAPage key='FORMALIZAR_CONTRATO_BA'/>
      </Route>
      <Route exact key='es' path='/landings/irpf22-planes-pension'>
        <FiscalExerciseIndividualPensionPlansPage key='LANDING_IRPF22_PLANES_PENSIONES'/>
      </Route>
      <Route exact key='es' path='/landings/irpf22-fondos-inversion'>
        <FiscalExerciseInvestmentFundsPage key='LANDING_IRPF22_FONDOS_INVERSION'/>
      </Route>
      <Route exact key='es' path='/landings/irpf22-planes-pension-empleo'>
        <FiscalExerciseEmploymentPensionPlans key='LANDING_IRPF22_PLANES_PENSIONES_EMPLEO'/>
      </Route>
      <Route exact key='es' path='/landings/euro-horizonte-informacion'>
        <EuroHorizonInformationPage key='LANDING_EURO_HORIZONTE_INFORMACION'/>
      </Route>
      <Route exact key='es' path='/landings/euro-horizonte-suscripcion'>
        <EuroHorizonSubscriptionPage key='LANDING_EURO_HORIZONTE_SUSCRIPCION'/>
      </Route>
      <Route exact key='es' path='/landings/euro-horizonte-cartera'>
        <EuroHorizonWalletPage key='LANDING_EURO_HORIZONTE_CARTERA'/>
      </Route>
      <Route exact key='es' path='/sostenibilidad'>
        <IsrInFonditelPage key='LANDING_SOSTENIBILIDAD'/>
      </Route>
      <Route exact key='es' path='/landings/oficina'>
        <OfficePage key='LANDING_OFICINA'/>
      </Route>
      <Route exact key='es' path='/landings/ere'>
        <RecordEmploymentRegulationPage key='LANDING_ERE'/>
      </Route>
      <Route exact key='es' path='/landings/ejercicio-fiscal'>
        <FiscalExercisePage key='LANDING_IRPF'/>
      </Route>
      <Route exact key='en' path='/en'>
        <HomePage />
      </Route>
      <Route exact key='en' path='/en/our-company'>
        <AboutUsPage key='NOSOTROS'/>
      </Route>
      <Route exact key='en' path='/en/employment-system-pension-plans'>
        <EmploymentSystemPensionPlansPage key='PLANES_PENSIONES_AUTONOMOS_PUBLICO'/>
      </Route>
      <Route exact key='en' path='/en/employment-system-pension-plans/solutions-companies'>
        <SolutionForCompaniesPage key='PLANES_PENSIONES_AUTONOMOS_SOLUCIONES_EMPRESAS_PUBLICO'/>
      </Route>
      <Route exact key='en' path='/en/employment-system-pension-plans/solutions-freelancers'>
        <SolutionsForFreelancersPage key='PLANES_PENSIONES_AUTONOMOS_SOLUCIONES_AUTONOMOS_PUBLICO'/>
      </Route>
      <Route exact key='en' path='/en/investment-funds'>
        <InvestmentFundsPage key='FONDOS_INVERSION_PUBLICO'/>
      </Route>
      <Route exact key='en' path='/en/investment-funds/:slug'>
        <InvestmentFundsDetailPage key='FONDOS_INVERSION_PUBLICO'/>
      </Route>
      <Route exact key='en' path='/en/pension-plans'>
        <PensionPlansPage key='PLANES_PENSIONES_PUBLICO'/>
      </Route>
      <Route exact key='en' path='/en/pension-plans/:slug'>
        <PensionPlansDetailPage key='PLANES_PENSIONES_PUBLICO'/>
      </Route>
      <Route exact key='en' path='/en/for-investor'>
        <ForInvestorPage key='INVERSOR'/>
      </Route>
      <Route exact key='en' path='/en/for-investor/commission-savings-calculator'>
        <CommissionSavingsCalculatorPage key='CALCULADORA_AHORRO_COMISIONES'/>
      </Route>
      <Route exact key='en' path='/en/for-investor/calculator-final-capital'>
        <FinalCapitalCalculatorPage key='CALCULADORA_CAPITAL_FINAL_AHORRO_FISCAL'/>
      </Route>
      <Route exact key='en' path='/en/for-investor/customize-investment'>
        <CustomizeInvestmentFundPage key='SIMULADOR_DECIDE_TU_INVERSION'/>
      </Route>
      <Route exact key='en' path='/en/for-investor/complementary-savings-calculator'>
        <ComplementarySavingsCalculatorPage key='INVERSOR_CALCULADORA_AHORRO_COMPLEMENTARIO'/>
      </Route>
      <Route exact key='en' path='/en/news'>
        <NewsPage key='NOTICIAS'/>
      </Route>
      <Route exact key='en' path='/en/news/:id/:preview?'>
        <NewsDetailPage key='NOTICIAS_DETALLE'/>
      </Route>
      <Route exact key='en' path='/en/contact'>
        <ContactPage key='CONTACTO_PUBLICO'/>
      </Route>
      <Route exact key='es' path='/en/technical-claims'>
        <TechnicalClaimsPage key='RECLAMACIONES_TECNICAS'/>
      </Route>
      <Route exact key='en' path='/en/certificates'>
        <CertificatesPage key='CERTIFICADOS'/>
      </Route>
      <Route exact key='en' path='/en/legal-notice'>
        <LegalNoticePage key='AVISO_LEGAL'/>
      </Route>
      <Route exact key='en' path='/en/cookies-policy'>
        <CookiesPolicyPage key='POLITICA_COOKIES'/>
      </Route>
      <Route exact key='en' path='/en/copyright'>
        <CopyrightPage key='COPYRIGHT_PUBLICO'/>
      </Route>
      <Route exact key='en' path='/en/operational-web'>
        <OperationalWebPage key='OPERATIVA_WEB_PUBLICO'/>
      </Route>
      <Route exact key='en' path='/en/data-protection'>
        <DataProtectionPage key='PROTECCION_DE_DATOS_PUBLICO'/>
      </Route>
      <Route exact key='en' path='/en/onboarding-init-app'>
        <OnBoardingInitAppPage key='ONBOARDING_INIT_APP'/>
      </Route>
      <Route exact key='en' path='/en/login'>
        <LoginPage key='LOGIN'/>
      </Route>
      <Route exact key='en' path='/en/reset-password'>
        <LoginPage key='LOGIN_RP'/>
      </Route>
      <Route exact key='en' path='/en/reset-operation-key'>
        <LoginPage key='LOGIN_RCO'/>
      </Route>
      <Route exact key='en' path='/en/biometric-login'>
        <BiometricLoginPage />
      </Route>
      <Route exact key='en' path='/en/user-blocked'>
        <UserBlockedPage key='USUARIO_BLOQUEADO'/>
      </Route>
      <Route exact key='en' path='/en/are-not-user'>
        <AlreadyUserPage />
      </Route>
      <Route exact key='en' path='/en/register'>
        <RegisterPage key='REGISTRO'/>
      </Route>
      <Route exact key='en' path='/en/activate-account'>
        <RegisterPage key='REGISTRO_ACTIVAR_CUENTA'/>
      </Route>
      <Route exact key='en' path='/en/incomplete-registration'>
        <IncompleteRegistrationPage key='REGISTRO_INCOMPLETO'/>
      </Route>
      <Route exact key='en' path='/en/private-area/select-profile'>
        <SelectProfile />
      </Route>
      <Route exact key='en' path='/en/private-area/operational-web'>
        <UserOperationalWebPage key='OPERATIVA_WEB'/>
      </Route>
      <Route exact key='en' path='/en/private-area/data-protection'>
        <UserDataProtectionPage key='PROTECCION_DE_DATOS'/>
      </Route>
      <Route exact key='en' path='/en/private-area/copyright'>
        <UserCopyrightPage key='COPYRIGHT'/>
      </Route>
      <Route exact key='en' path='/en/private-area/contact'>
        <UserContactPage key='CONTACTO'/>
      </Route>
      <Route exact key='en' path='/en/private-area/user-menu'>
        <UserMenuPage key='MENU_USUARIO'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/global-position'>
        <GlobalPositionCustomerPage key='POSICION_GLOBAL'/>
      </Route>
      
      <Route exact key='en' path='/en/private-area/customers/pension-plans/employment-plans'>
        <EmploymentPlansCustomerPage key='PLANES_PENSIONES_EMPLEO'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/pension-plans/employment-plans/product-information'>
        <InfoEmploymentPlansCustomerPage key='PLANES_PENSIONES_EMPLEO_INFORMACION_PRODUCTO'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/pension-plans/employment-plans/contract'>
        <ContractEmploymentPlansCustomerPage key='PLANES_PENSIONES_EMPLEO_CONTRATAR'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/pension-plans/employment-plans/operate'>
        <OperateEmploymentPlansCustomerPage key='PLANES_PENSIONES_EMPLEO_OPERAR'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/pension-plans/employment-plans/contribution'>
        <ContributionsEmploymentPlansCustomerPage key='PLANES_PENSIONES_EMPLEO_OPERAR_APORTACION'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/pension-plans/employment-plans/mobilize'>
        <MobilizeEmploymentPlansCustomerPage key='PLANES_PENSIONES_EMPLEO_OPERAR_MOVILIZAR'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/pension-plans/individual-plans'>
        <IndividualPlansCustomerPage key='PLANES_PENSIONES_INDIVIDUALES'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/pension-plans/individual-plans/product-information'>
        <InfoIndividualPlansCustomerPage key='PLANES_PENSIONES_INDIVIDUALES_INFORMACION_PRODUCTO'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/pension-plans/individual-plans/contract'>
        <ContractIndividualPlansCustomerPage key='PLANES_PENSIONES_INDIVIDUALES_CONTRATAR'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/pension-plans/individual-plans/operate'>
        <OperateIndividualPlansCustomerPage key='PLANES_PENSIONES_INDIVIDUALES_OPERAR'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/pension-plans/individual-plans/contribution'>
        <ContributionsIndividualPlansCustomerPage key='PLANES_PENSIONES_INDIVIDUALES_OPERAR_APORTACION'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/pension-plans/individual-plans/mobilize'>
        <MobilizeIndividualPlansCustomerPage key='PLANES_PENSIONES_INDIVIDUALES_OPERAR_MOVILIZAR'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/pension-plans/simplified-employment-plans'>
        <SimplifiedEmploymentPlansCustomerPage key='PLANES_PENSIONES_EMPLEO_SIMPLIFICADOS'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/pension-plans/simplified-employment-plans/product-information'>
        <InfoSimplifiedEmploymentPlansCustomerPage key='PLANES_PENSIONES_EMPLEO_SIMPLIFICADOS_INFORMACION_PRODUCTO'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/pension-plans/simplified-employment-plans/contract'>
        <ContractSimplifiedEmploymentPlansCustomerPage key='PLANES_PENSIONES_EMPLEO_SIMPLIFICADOS_CONTRATAR'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/pension-plans/simplified-employment-plans/sign'>
        <SignContractSimplifiedEmploymentPlansCustomerPage key='PLANES_PENSIONES_EMPLEO_SIMPLIFICADOS_FIRMAR'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/pension-plans/simplified-employment-plans/operate'>
        <OperateSimplifiedEmploymentPlansCustomerPage key='PLANES_PENSIONES_EMPLEO_SIMPLIFICADOS_OPERAR'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/pension-plans/simplified-employment-plans/contribution'>
        <ContributionsSimplifiedEmploymentPlansCustomerPage key='PLANES_PENSIONES_EMPLEO_SIMPLIFICADOS_APORTACION'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/pension-plans/simplified-employment-plans/mobilize'>
        <MobilizeSimplifiedEmploymentPlansCustomerPage key='PLANES_PENSIONES_EMPLEO_SIMPLIFICADOS_MOVILIZAR'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/investment-funds'>
        <InvestmentFundsCustomerPage key='FONDOS_INVERSION'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/investment-funds/product-information'>
        <InfoInvestmentFundsCustomerPage key='FONDOS_INVERSION_INFORMACION_PRODUCTO'/>
      </Route>
      <Route  exact key='en' path='/en/private-area/customers/investment-funds/contract'>
        <ContractInvestmentFundsCustomerPage key='FONDOS_INVERSION_CONTRATAR'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/investment-funds/operate'>
        <OperateInvestmentFundsCustomerPage key='FONDOS_INVERSION_OPERAR'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/investment-funds/operate/subscription'>
        <SubscriptionInvestmentFundsCustomerPage key='FONDOS_INVERSION_OPERAR_SUSCRIPCION'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/investment-funds/operate/refund'>
        <RefundInvestmentFundsCustomerPage key='FONDOS_INVERSION_OPERAR_REEMBOLSO'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/investment-funds/operate/transfer'>
        <TransferInvestmentFundsCustomerPage key='FONDOS_INVERSION_OPERAR_TRASPASO'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/other-products'>
        <OtherProductsCustomerPage key='OTROS_PRODUCTOS'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/other-products/operate'>
        <OperateOtherProductsCustomerPage key='OTROS_PRODUCTOS_OPERAR'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/tax-information'>
        <TaxInformationCustomerPage key='INFORMACION_FISCAL'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/tax-information/pension-plans'>
        <TaxInformationPensionPlansCustomerPage key='INFORMACION_FISCAL_PLANES_PENSIONES'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/tax-information/investment-funds'>
        <TaxInformationInvestmentFundsCustomerPage key='INFORMACION_FISCAL_FONDOS_INVERSION'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/online-correspondence'>
        <OnlineCorrespondenceCustomerPage key='CORRESPONDENCIA_ONLINE'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/online-correspondence/configure'>
        <ConfigureOnlineCorrespondenceCustomerPage key='CORRESPONDENCIA_ONLINE_CONFIGURAR'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/operations-in-progress'>
        <OperationsInProgressCustomerPage key='OPERACIONES_CURSO'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/operations-in-progress/detail'>
        <OperationsInProgressDetailCustomerPage key='OPERACIONES_CURSO_DETALLE'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/notices'>
        <NoticesCustomerPage key='AVISOS'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/contracts'>
        <ContractsCustomerPage key='CONTRATOS'/>
      </Route>
      <Route exact key='en' path='/en/private-area/formalize-account'>
        <FormalizeContractSignaturit key='FORMALIZAR_CONTRATO'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/profile'>
        <ProfileCustomerPage key='PERFIL'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/change-password'>
        <ChangePasswordCustomerPage key='CAMBIAR_PASSWORD'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/change-operations-key'>
        <ChangeOperationsKeyCustomerPage key='CAMBIAR_CLAVE_OPERACIONES'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/contract'>
        <ContractCustomerPage key='CONTRATAR'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers/menu'>
        <MenuCustomerPage key='CLIENTES_MENU'/>
      </Route>
      <Route exact key='en' path='/en/private-area/customers'>
        <GlobalPositionCustomerPage key='POSICION_GLOBAL_CLIENTES'/>
      </Route>
      <Route exact key='en' path='/en/private-area/control-commision/global-position'>
        <GlobalPositionControlCommisionPage key='POSICION_GLOBAL_COMISION_CONTROL'/>
      </Route>
      <Route exact key='en' path='/en/private-area/control-commision/legal-documentation'>
        <LegalDocumentationControlCommisionPage key='COMISION_CONTROL_DOCUMENTACION_LEGAL'/>
      </Route>
      <Route exact key='en' path='/en/private-area/control-commision/monthly-report'>
        <MonthlyReportControlCommisionPage key='COMISION_CONTROL_INFORME_MENSUAL'/>
      </Route>
      <Route exact key='en' path='/en/private-area/control-commision/printed-matter'>
        <PrintedMatterControlCommisionPage key='COMISION_CONTROL_IMPRESOS'/>
      </Route>
      <Route exact key='en' path='/en/private-area/control-commision/other-queries'>
        <OtherQueriesControlCommisionPage key='COMISION_CONTROL_OTRAS_CONSULTAS'/>
      </Route>
      <Route exact key='en' path='/en/private-area/control-commision'>
        <GlobalPositionControlCommisionPage key='COMISION_CONTROL'/>
      </Route>
      <Route exact key='en' path='/en/private-area/promoter/global-position'>
        <GlobalPositionPromoterPage key='POSICION_GLOBAL_PROMOTOR'/>
      </Route>
      <Route exact key='en' path='/en/private-area/promoter/register-participants'>
        <RegisterParticipantsPromoterPage key='PROMOTOR_ALTA_PARTICIPES'/>
      </Route>
      <Route exact key='en' path='/en/private-area/promoter/contributions'>
        <ContributionsPromoterPage key='PROMOTOR_APORTACIONES'/>
      </Route>
      <Route exact key='en' path='/en/private-area/promoter/worker-profile'>
        <WorkerProfilePromoterPage key='PROMOTOR_TIPO_TRABAJADOR'/>
      </Route>
      <Route exact key='en' path='/en/private-area/promoter/legal-documentation'>
        <LegalDocumentationPromoterPage key='PROMOTOR_DOCUMENTACION_LEGAL'/>
      </Route>
      <Route exact key='en' path='/en/private-area/promoter/monthly-report'>
        <MonthlyReportPromoterPage key='PROMOTOR_INFORME_MENSUAL'/>
      </Route>
      <Route exact key='en' path='/en/private-area/promoter/printed-matter'>
        <PrintedMatterPromoterPage key='PROMOTOR_IMPRESOS'/>
      </Route>
      <Route exact key='en' path='/en/private-area/promoter/other-queries'>
        <OtherQueriesPromoterPage key='PROMOTOR_OTRAS_CONSULTAS'/>
      </Route>
      <Route exact key='en' path='/en/private-area/promoter'>
        <GlobalPositionPromoterPage key='PROMOTOR'/>
      </Route>
      <Route exact key='en' path='/en/maintenance'>
        <MaintenancePage key='MANTENIMIENTO'/>
      </Route>
      <Route exact key='en' path='/en/sustainability'>
        <IsrInFonditelPage key='LANDING_SOSTENIBILIDAD'/>
      </Route>
      <Route exact key='en' path='/en/landings/irpf22-planes-pension'>
        <FiscalExerciseIndividualPensionPlansPage key='LANDING_IRPF22_PLANES_PENSIONES'/>
      </Route>
      <Route exact key='en' path='/en/landings/irpf22-fondos-inversion'>
        <FiscalExerciseInvestmentFundsPage key='LANDING_IRPF22_FONDOS_INVERSION'/>
      </Route>
      <Route exact key='en' path='/en/landings/irpf22-planes-pension-empleo'>
        <FiscalExerciseEmploymentPensionPlans key='LANDING_IRPF22_PLANES_PENSIONES_EMPLEO'/>
      </Route>
      <Route exact key='en' path='/en/landings/euro-horizonte-informacion'>
        <EuroHorizonInformationPage key='LANDING_EURO_HORIZONTE_INFORMACION'/>
      </Route>
      <Route exact key='en' path='/en/landings/euro-horizonte-suscripcion'>
        <EuroHorizonSubscriptionPage key='LANDING_EURO_HORIZONTE_SUSCRIPCION'/>
      </Route>
      <Route exact key='en' path='/en/landings/euro-horizonte-cartera'>
        <EuroHorizonWalletPage key='LANDING_EURO_HORIZONTE_CARTERA'/>
      </Route>
      <Route exact key='en' path='/en/landings/oficina'>
        <OfficePage key='LANDING_OFICINA'/>
      </Route>
      <Route exact key='en' path='/en/landings/ere'>
        <RecordEmploymentRegulationPage key='LANDING_ERE'/>
      </Route>
      <Route exact key='es' path='/landings/ejercicio-fiscal'>
        <FiscalExercisePage key='LANDING_IRPF'/>
      </Route>
      <Route exact key='en' path='/en/404'>
        <Error404Page key='404'/>
      </Route>
      <Route path='*'>
        <Redirect to='/404'></Redirect>
      </Route>

    </Switch>
  )};

export function getHeaderNavigation() : NavOption[] {
  return [    {title:'menu_header_about_us', page: AboutUsPage},
              {title:'menu_header_employment_system_pension_plans', page: EmploymentSystemPensionPlansPage},
              {title:'menu_header_investment_funds', page: InvestmentFundsPage},
              {title:'menu_header_pension_plans', page: PensionPlansPage},
              {title:'menu_header_for_investor', page: ForInvestorPage},
              {title:'menu_header_news', page: NewsPage}];
}


export function getHeaderEuroHorizonte() : NavOption[] {
  return [    {title:'menu_header_euro_horizonte_information', page: EuroHorizonInformationPage},
              {title:'menu_header_euro_horizonte_subscription_refund', page: EuroHorizonSubscriptionPage},
              {title:'menu_header_euro_horizonte_wallet', page: EuroHorizonWalletPage}];
}


export function getFooterNavigation(searcher:boolean=false) : NavOption[] {
  const language = getCurrentLang();

  const optionEmploymentSystemPensionPlans ={title:'menu_footer_employment_system_pension_plans', page: EmploymentSystemPensionPlansPage,  options: [
    {title: 'menu_footer_employment_system_pension_plans_solution_for_companies', page: SolutionForCompaniesPage},
    {title: 'menu_footer_employment_system_pension_plans_solution_for_freelancers', page: SolutionsForFreelancersPage}
  ]};


  const optionPensionPlans = {title:'menu_footer_pension_plans', page: PensionPlansPage, options: [
    {title: 'menu_footer_pension_plans_all_plans', page: PensionPlansPage, anchor: language==='es' ? '#planes' : '#plans'},
    {title: 'menu_footer_pension_plans_how_choose_plan', page: PensionPlansPage, anchor: language==='es' ? '#elegir-plan' : '#choose-plan'},
    {title: 'menu_footer_pension_plans_faqs', page: PensionPlansPage, anchor: language==='es' ? '#faqs' : '#faqs'}
  ]};

  const optionInvestmentFunds = {title:'menu_footer_investment_funds', page: InvestmentFundsPage, options: [
    {title: 'menu_footer_investment_funds_all_funds', page: InvestmentFundsPage, anchor: language==='es' ? '#fondos' : '#funds'},
    {title: 'menu_footer_investment_funds_how_choose_fund', page: InvestmentFundsPage, anchor: language==='es' ? '#elegir-fondo' : '#choose-fund'},
    {title: 'menu_footer_investment_funds_faqs', page: InvestmentFundsPage, anchor: language==='es' ? '#faqs' : '#faqs'}
  ]};

  const optionForInvestor = {title:'menu_footer_for_investor', page: ForInvestorPage, options: [
    {title: 'menu_footer_for_investor_glossary_terms', page: ForInvestorPage, anchor: language==='es' ? '#glosario-terminos' : '#glossary-terms'},
    {title: 'menu_footer_for_investor_calculators', page: ForInvestorPage, anchor: language==='es' ? '#calculadoras' : '#calculators'},
    {title: 'menu_footer_for_investor_faqs', page: ForInvestorPage, anchor: language==='es' ? '#faqs' : '#faqs'},
    {title: 'menu_footer_for_investor_guide_reports', page: ForInvestorPage, anchor: language==='es' ? '#guias-informes' : '#guides-reports'},
    {title: 'menu_footer_for_investor_customer_defence', page: ForInvestorPage, anchor: language==='es' ? '#guias-informes' : '#guides-reports'}
  ]};

  const optionFonditel = {title:'site_name', options: [
    {title: 'menu_footer_about_us', page: AboutUsPage},
    {title: 'menu_footer_about_us_certificates', page: CertificatesPage},
    {title: 'menu_footer_about_us_legal_advise', page: LegalNoticePage},
    {title: 'menu_footer_about_us_cookies_policy', page: CookiesPolicyPage},
    {title: 'menu_footer_about_us_copyright', page: CopyrightPage},
    {title: 'menu_footer_about_us_data_protection', page: DataProtectionPage},
    {title: 'menu_footer_about_us_operational_web', page: OperationalWebPage},
    {title: 'menu_footer_about_us_sustainability', page: IsrInFonditelPage},
    {title: 'menu_footer_about_us_inquiries_complaints_channel', href:  language==='es' ? 'https://www.telefonica.com/es/canal-denuncias-consultas/' : 'https://www.telefonica.com/en/whistleblowing-queries-channel/'},
    {title: 'menu_footer_about_us_technical_claims', page: TechnicalClaimsPage},
    {title: 'menu_footer_health_and_safety_policy', href:  language==='es' ? 'https://www.fonditel.es/wp-content/uploads/2024/07/FONDITEL POLITICA SEGURIDAD SALUD Y BIENESTAR LABORAL .pdf' : 'https://www.fonditel.es/wp-content/uploads/2024/07/FONDITEL POLITICA SEGURIDAD SALUD Y BIENESTAR LABORAL .pdf'},
  ]};

  if(searcher){
    return [optionPensionPlans, optionInvestmentFunds, optionEmploymentSystemPensionPlans];
  }

  return [optionEmploymentSystemPensionPlans, optionPensionPlans, optionInvestmentFunds, optionForInvestor, optionFonditel];
}

export function getPrivateNavigationResponsive(role?: string) : NavOption[] {

  let menu : NavOption[] = [];
  if(!role){
    const user = getUser();
    if(user){
      role = user.selectedRole;
    }
  }

  switch(role){
    case 'client':
      havePermission(process.env.REACT_APP_MENU_POSICION_GLOBAL) &&
        menu.push({title:'menu_private_responsive_customer_home', page: GlobalPositionCustomerPage});

      havePermission(process.env.REACT_APP_MENU_OPERACIONES_CURSO) &&
        menu.push({title:'menu_private_responsive_customer_operations_in_progress', page: OperationsInProgressCustomerPage});

      havePermission(process.env.REACT_APP_MENU_NUEVO_CONTRATO) &&
        menu.push({title:'menu_private_responsive_customer_contract', page: ContractCustomerPage});

      havePermission(process.env.REACT_APP_MENU_CORRESPONDENCIA_ONLINE) &&
        menu.push({title:'menu_private_responsive_customer_online_correspondence', page: OnlineCorrespondenceCustomerPage});

      (havePermission(process.env.REACT_APP_MENU_INFORMACION_FISCAL_PLANES_PENSIONES) || havePermission(process.env.REACT_APP_MENU_INFORMACION_FISCAL_FONDOS_INVERSION)) &&
        menu.push({title:'menu_private_responsive_customer_tax_information', page: TaxInformationCustomerPage});

      havePermission(process.env.REACT_APP_MENU_OPERATIVA_WEB_CAMBIAR_SITUACION_CONTRATOS) &&
        menu.push({title:'menu_private_responsive_customer_contracts', page: ContractsCustomerPage});

      break;
      case 'promoter':
        havePermission(process.env.REACT_APP_AREA_PROMOTORES) &&
          menu.push({title:'menu_private_responsive_promoter_home', page: GlobalPositionPromoterPage});

        havePermission(process.env.REACT_APP_AREA_PROMOTORES_ALTA_PARTICIPES) &&
          menu.push({title:'menu_private_responsive_promoter_register_participants', page: RegisterParticipantsPromoterPage});

        havePermission(process.env.REACT_APP_AREA_PROMOTORES_APORTACIONES) &&
          menu.push({title:'menu_private_responsive_promoter_contributions', page: ContributionsPromoterPage});

        havePermission(process.env.REACT_APP_AREA_PROMOTORES) &&
          menu.push({title:'menu_private_responsive_promoter_worker_profile', page: WorkerProfilePromoterPage});

        havePermission(process.env.REACT_APP_AREA_PROMOTORES_DOCUMENTACION_LEGAL) &&
          menu.push({title:'menu_private_responsive_promoter_legal_documentation', page: LegalDocumentationPromoterPage});

        havePermission(process.env.REACT_APP_AREA_PROMOTORES_INFORME_MENSUAL) &&
          menu.push({title:'menu_private_responsive_promoter_monthly_report', page: MonthlyReportPromoterPage});

        havePermission(process.env.REACT_APP_AREA_PROMOTORES_IMPRESOS) &&
          menu.push({title:'menu_private_responsive_promoter_printed_matter', page: PrintedMatterPromoterPage});

        havePermission(process.env.REACT_APP_AREA_PROMOTORES_OTRAS_CONSULTAS) &&
          menu.push({title:'menu_private_responsive_promoter_other_queries', page: OtherQueriesPromoterPage});

        break;
      case 'control-commision':
        havePermission(process.env.REACT_APP_AREA_COMISION_CONTROL) &&
          menu.push({title:'menu_private_responsive_control_commision_home', page: GlobalPositionControlCommisionPage});

        havePermission(process.env.REACT_APP_AREA_COMISION_CONTROL_DOCUMENTACION_LEGAL) &&
          menu.push({title:'menu_private_responsive_control_commision_legal_documentation', page: LegalDocumentationControlCommisionPage});

        havePermission(process.env.REACT_APP_AREA_COMISION_CONTROL_INFORME_MENSUAL) &&
          menu.push({title:'menu_private_responsive_control_commision_monthly_report', page: MonthlyReportControlCommisionPage});

        havePermission(process.env.REACT_APP_AREA_COMISION_CONTROL_IMPRESOS) &&
          menu.push({title:'menu_private_responsive_control_commision_printed_matter', page: PrintedMatterControlCommisionPage});

        havePermission(process.env.REACT_APP_AREA_COMISION_CONTROL_OTRAS_CONSULTAS) &&
          menu.push({title:'menu_private_responsive_control_commision_other_queries', page: OtherQueriesControlCommisionPage});

        break;
  }
  //menu.push({title:'menu_private_responsive_fonditel', page: GlobalPositionCustomerPage, icon: <IconMenuRegular color="currentColor"  />});

  return menu;
}

export function getFooterPrivateNavigation(role?: string) : NavOption[] {

  let menu : NavOption[] = [];
  if(!role){
    const user = getUser();
    if(user){
      role = user.selectedRole;
    }
  }
  switch(role){
    case 'client':
      havePermission(process.env.REACT_APP_MENU_POSICION_GLOBAL) &&
        menu.push({title:'menu_private_footer_customer_home', page: GlobalPositionCustomerPage, icon: <IconHomeRegular color="currentColor"  />, iconSelected: <IconHomeFilled color="currentColor"  />});

      havePermission(process.env.REACT_APP_MENU_OPERACIONES_CURSO) &&
        menu.push({title:'menu_private_footer_customer_operations_in_progress', page: OperationsInProgressCustomerPage, icon: <IconExchangeLight color="currentColor"  />, iconSelected: <IconExchangeFilled color="currentColor"  />});

      havePermission(process.env.REACT_APP_MENU_NUEVO_CONTRATO) &&
        menu.push({title:'menu_private_footer_customer_contract', page: ContractCustomerPage, icon: <IconAddMoreCircleRegular color="currentColor"  />, iconSelected: <IconAddMoreCircleFilled color="currentColor"  />});

      havePermission(process.env.REACT_APP_MENU_CORRESPONDENCIA_ONLINE) &&
        menu.push({title:'menu_private_footer_customer_online_correspondence', page: OnlineCorrespondenceCustomerPage, icon: <IconDocumentsRegular color="currentColor"  />, iconSelected: <IconDocumentsFilled color="currentColor"  />});

      havePermission(process.env.REACT_APP_MENU_POSICION_GLOBAL) &&
        menu.push({title:'menu_private_footer_customer_menu', page: MenuCustomerPage, icon: <IconMenuRegular color="currentColor"  />});
      break;
    case 'promoter':
      havePermission(process.env.REACT_APP_AREA_PROMOTORES) &&
        menu.push({title:'menu_private_footer_promoter_home', page: GlobalPositionPromoterPage, icon: <IconHomeRegular color="currentColor"  />, iconSelected: <IconHomeFilled color="currentColor"  />});

      havePermission(process.env.REACT_APP_AREA_PROMOTORES_DOCUMENTACION_LEGAL) &&
        menu.push({title:'menu_private_footer_promoter_legal_documentation', page: LegalDocumentationPromoterPage, icon: <IconJusticeRegular color="currentColor"  />, iconSelected: <IconJusticeFilled color="currentColor"  />});

      havePermission(process.env.REACT_APP_AREA_PROMOTORES_INFORME_MENSUAL) &&
        menu.push({title:'menu_private_footer_promoter_monthly_report', page: MonthlyReportPromoterPage, icon: <IconPayInvoiceRegular color="currentColor"  />, iconSelected: <IconPayInvoiceFilled color="currentColor"  />});

      havePermission(process.env.REACT_APP_AREA_PROMOTORES_IMPRESOS) &&
        menu.push({title:'menu_private_footer_promoter_printed_matter', page: PrintedMatterPromoterPage, icon: <IconDocumentsRegular color="currentColor"  />, iconSelected: <IconDocumentsFilled color="currentColor"  />});

      havePermission(process.env.REACT_APP_AREA_PROMOTORES_OTRAS_CONSULTAS) &&
        menu.push({title:'menu_private_footer_promoter_other_queries', page: OtherQueriesPromoterPage, icon: <IconSearchFileRegular color="currentColor"  />, iconSelected: <IconSearchFileFilled color="currentColor"  />});
      break;
    case 'control-commision':
      havePermission(process.env.REACT_APP_AREA_COMISION_CONTROL) &&
        menu.push({title:'menu_private_footer_control_commision_home', page: GlobalPositionControlCommisionPage, icon: <IconHomeRegular color="currentColor"  />, iconSelected: <IconHomeFilled color="currentColor"  />});

      havePermission(process.env.REACT_APP_AREA_COMISION_CONTROL_DOCUMENTACION_LEGAL) &&
        menu.push({title:'menu_private_footer_control_commision_legal_documentation', page: LegalDocumentationControlCommisionPage, icon: <IconJusticeRegular color="currentColor"  />, iconSelected: <IconJusticeFilled color="currentColor"  />});

      havePermission(process.env.REACT_APP_AREA_COMISION_CONTROL_INFORME_MENSUAL) &&
        menu.push({title:'menu_private_footer_control_commision_monthly_report', page: MonthlyReportControlCommisionPage, icon: <IconPayInvoiceRegular color="currentColor"  />, iconSelected: <IconPayInvoiceFilled color="currentColor"  />});

      havePermission(process.env.REACT_APP_AREA_COMISION_CONTROL_IMPRESOS) &&
        menu.push({title:'menu_private_footer_control_commision_printed_matter', page: PrintedMatterControlCommisionPage, icon: <IconDocumentsRegular color="currentColor"  />, iconSelected: <IconDocumentsFilled color="currentColor"  />});

      havePermission(process.env.REACT_APP_AREA_COMISION_CONTROL_OTRAS_CONSULTAS) &&
        menu.push({title:'menu_private_footer_control_commision_other_queries', page: OtherQueriesControlCommisionPage, icon: <IconSearchFileRegular color="currentColor"  />, iconSelected: <IconSearchFileFilled color="currentColor"  />});
      break;
  }
  return menu;
}



export function getPrivateNavigation(role?: string) : NavOption[] {
  const user = getUser();
  if(!role){
    if(user){
      role = user.selectedRole;
    }
  }

  let menu : NavOption[] = [];
  switch(role){
    case 'client':
      havePermission(process.env.REACT_APP_MENU_POSICION_GLOBAL) &&
        menu.push({title:'menu_private_customer_global_position', page: GlobalPositionCustomerPage, icon: <IconTachometerRegular color="currentColor"  />});


      let menuPP : NavOption[] = [];
      if(havePermission(process.env.REACT_APP_MENU_PLANES_PENSIONES)){
        menuPP.push({title: 'menu_private_customer_pension_plans_employment_plans', page: EmploymentPlansCustomerPage});
        menuPP.push({title: 'menu_private_customer_pension_plans_individual_plans', page: IndividualPlansCustomerPage});

        if(user.autonomo===true){
          menuPP.push({title: 'menu_private_customer_pension_plans_simplified_employment_plans', page: SimplifiedEmploymentPlansCustomerPage});
        }

        menu.push({title:'menu_private_customer_pension_plans', icon: <IconUmbrellaRegular color="currentColor"  />,  options: menuPP});
      }

      havePermission(process.env.REACT_APP_MENU_FONDOS_INVERSION) &&
        menu.push({title:'menu_private_customer_investment_funds', page: InvestmentFundsCustomerPage, icon: <IconEcoRegular color="currentColor"  />});

      havePermission(process.env.REACT_APP_MENU_OTROS_PRODUCTOS) &&
        menu.push({title:'menu_private_customer_other_products', page: OtherProductsCustomerPage, icon: <IconFlowerRegular color="currentColor"  />});

      let menuIF : NavOption[] = [];
      havePermission(process.env.REACT_APP_MENU_INFORMACION_FISCAL_PLANES_PENSIONES) &&
        menuIF.push({title: 'menu_private_customer_tax_information_pension_plans', page: TaxInformationPensionPlansCustomerPage});

      havePermission(process.env.REACT_APP_MENU_INFORMACION_FISCAL_FONDOS_INVERSION) &&
        menuIF.push({title: 'menu_private_customer_tax_information_investment_funds', page: TaxInformationInvestmentFundsCustomerPage});

      menuIF.length>0 &&
        menu.push({title:'menu_private_customer_tax_information', icon: <IconBalanceRegular  color="currentColor" />,  options: menuIF});

      havePermission(process.env.REACT_APP_MENU_CORRESPONDENCIA_ONLINE) &&
        menu.push({title:'menu_private_customer_online_correspondence', page: OnlineCorrespondenceCustomerPage, icon: <IconComputerRegular color="currentColor"  />});
      break;
    case 'promoter':
      havePermission(process.env.REACT_APP_AREA_PROMOTORES) &&
        menu.push({title:'menu_private_promoter_global_position', page: GlobalPositionPromoterPage, icon: <IconTachometerRegular color="currentColor"  />});

      havePermission(process.env.REACT_APP_AREA_PROMOTORES_ALTA_PARTICIPES) &&
        menu.push({title:'menu_private_promoter_register_participants', page: RegisterParticipantsPromoterPage, icon: <IconUserAccountRegular color="currentColor"  />});

      havePermission(process.env.REACT_APP_AREA_PROMOTORES_APORTACIONES) &&
        menu.push({title:'menu_private_promoter_contributions', page: ContributionsPromoterPage, icon: <IconDollarSymbolCircleRegular color="currentColor"  />});

      havePermission(process.env.REACT_APP_AREA_PROMOTORES) &&
        menu.push({title:'menu_private_promoter_worker_profile', page: WorkerProfilePromoterPage, icon: <IconPeopleNetworkRegular color="currentColor"  />});

      havePermission(process.env.REACT_APP_AREA_PROMOTORES_DOCUMENTACION_LEGAL) &&
        menu.push({title:'menu_private_promoter_legal_documentation', page: LegalDocumentationPromoterPage, icon: <IconJusticeRegular color="currentColor"  />});

      havePermission(process.env.REACT_APP_AREA_PROMOTORES_INFORME_MENSUAL) &&
        menu.push({title:'menu_private_promoter_monthly_report', page: MonthlyReportPromoterPage, icon: <IconPayInvoiceRegular color="currentColor"  />});

      havePermission(process.env.REACT_APP_AREA_PROMOTORES_IMPRESOS) &&
        menu.push({title:'menu_private_promoter_printed_matter', page: PrintedMatterPromoterPage, icon: <IconDocumentsRegular color="currentColor"  />});

      havePermission(process.env.REACT_APP_AREA_PROMOTORES_OTRAS_CONSULTAS) &&
        menu.push({title:'menu_private_promoter_other_queries', page: OtherQueriesPromoterPage, icon: <IconSearchFileRegular color="currentColor"  />});

      break;
    case 'control-commision':
      havePermission(process.env.REACT_APP_AREA_COMISION_CONTROL) &&
        menu.push({title:'menu_private_control_commision_global_position', page: GlobalPositionControlCommisionPage, icon: <IconTachometerRegular color="currentColor"  />});

      havePermission(process.env.REACT_APP_AREA_COMISION_CONTROL_DOCUMENTACION_LEGAL) &&
        menu.push({title:'menu_private_control_commision_legal_documentation', page: LegalDocumentationControlCommisionPage, icon: <IconJusticeRegular color="currentColor"  />});

      havePermission(process.env.REACT_APP_AREA_COMISION_CONTROL_INFORME_MENSUAL) &&
        menu.push({title:'menu_private_control_commision_monthly_report', page: MonthlyReportControlCommisionPage, icon: <IconPayInvoiceRegular color="currentColor"  />});

      havePermission(process.env.REACT_APP_AREA_COMISION_CONTROL_IMPRESOS) &&
        menu.push({title:'menu_private_control_commision_printed_matter', page: PrintedMatterControlCommisionPage, icon: <IconDocumentsRegular color="currentColor"  />});

      havePermission(process.env.REACT_APP_AREA_COMISION_CONTROL_OTRAS_CONSULTAS) &&
        menu.push({title:'menu_private_control_commision_other_queries', page: OtherQueriesControlCommisionPage, icon: <IconSearchFileRegular color="currentColor"  />});

      break;
  }
  return menu;
}

export function getUserMenuNavigation(biometricIsAvailable:boolean, role?: string) : NavOption[] {
  const user = getUser();
  if(!role){
    if(user){
      role = user.selectedRole;
    }
  }

  let menu : NavOption[] = [];
  switch(role){
    case 'client':
      havePermission(process.env.REACT_APP_MENU_OPERATIVA_WEB_MIS_DATOS) &&
        menu.push({title:'menu_user_private_customer_profile', page: ProfileCustomerPage, icon: <IconUserAccountRegular color="currentColor"  />});

      havePermission(process.env.REACT_APP_MENU_OPERATIVA_WEB_CAMBIAR_PASSWORD) &&
        menu.push({title:'menu_user_private_customer_change_password', page: ChangePasswordCustomerPage, icon: <IconLockClosedRegular color="currentColor"  />});

      havePermission(process.env.REACT_APP_MENU_OPERATIVA_WEB_CAMBIAR_CLAVE_OPERACIONES) &&
        menu.push({title:'menu_user_private_customer_change_operations_key', page: ChangeOperationsKeyCustomerPage, icon: <IconNumericKeypadRegular color="currentColor"  />});

      havePermission(process.env.REACT_APP_MENU_CORRESPONDENCIA_ONLINE) &&
        menu.push({title:'menu_user_private_customer_online_correspondence', page: ConfigureOnlineCorrespondenceCustomerPage, icon: <IconDocumentsRegular color="currentColor"  />});
      break;
    case 'promoter':
      break;
    case 'control-commision':
      break;
  }

  (user.roles && user.roles.length>1)  &&
    menu.push({title:'menu_user_private_select_profile', page: SelectProfile, icon: <IconComputerUserRegular color="currentColor"  />});

  if(biometricIsAvailable){
    menu.push({title:'menu_user_private_biometric_login', page: BiometricLoginPage, icon: <IconFingerprintRegular color="currentColor"  />});
  }


  return menu;
}

export function getUrlNavigation(option?: React.FC): string {
  const language = getCurrentLang();

  if(option){
    let options : ReactElement[] = [];
    options = Pages()?.props.children;
    options = options.filter(item=>item && item.key && item.key===language);

    let find_option = options.find((item)=>item.props.children.type.name===option.name);

    if(find_option){
      return find_option.props.path;
    }
  }
  return getModeMaintenance() ? getUrlNavigation(MaintenancePage) : getUrlNavigation(Error404Page);
}
export function getOptionNavigationInverse(path: string){
  const language = getCurrentLang();


  let options : ReactElement[] = [];
  options = Pages()?.props.children;
  options = options.filter(item=>item && item.key && item.key===language);

  let findOption = options.find((item)=>item.props.path===path);

  if(!findOption){
    let to = path.lastIndexOf('/');
    to = to === -1 ? path.length : to;
    path = path.substring(0, to);
    findOption = options.find((item)=>item.props.path===path);
  }


  return findOption;
}


export function getUrlNavigationInverse(path: string, language:Language){
  let find_option = getOptionNavigationInverse(path);

  let options : ReactElement[] = [];
  options = Pages()?.props.children;
  options = options.filter(item=>item && item.key && item.key===language.value);

  if(find_option){
    find_option = options.find((item)=>find_option && item.props.children.type.name===find_option.props.children.type.name);

    if(find_option){
      return find_option.props.path;
    }
    return language.href;
  }

}
